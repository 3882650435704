import type { Config } from 'tailwindcss'

const TOOLBAR_WIDTH = 128
const SIDEBAR_WIDTH = 416
const MODEL_BUFFER_WIDTH = 600
export const VIEWER_OFFSET = TOOLBAR_WIDTH - SIDEBAR_WIDTH
export const BREAKPOINT = TOOLBAR_WIDTH + SIDEBAR_WIDTH + MODEL_BUFFER_WIDTH

const HALF_TILE_SIDE = (SIDEBAR_WIDTH - 2) / 2
const THIRD_TILE_SIDE = (SIDEBAR_WIDTH - 2) / 3

export default {
  content: [
    './src/customizers/olv-demo/**/*.{ts,tsx}',
    './src/customizers/smack-sportswear/**/*.{ts,tsx}',
    './src/customizers/claw-teamwear/**/*.{ts,tsx}',
    './src/platform/spectacle-base/**/*.{ts,tsx}',
  ],
  theme: {
    colors: {
      transparent: 'transparent',
      current: 'currentColor',
      white: '#ffffff',
      black: '#000000',
      orange: '#FF5500',
      orangeLight: '#ffddcc',
      grayLegacy: '#979797',
      lightGrey: '#e5e5e5',
      lighterGrey: '#f2f2f2',
      darkerWhite: '#fcfcfc',
      error: '#e32e0e',
      info: '#e3b10e',
      blue: '#007aff',
      'blue-100': '#007aff',
      'blue-200': '#0063CC',
      'blue-300': '#004A99',
      blueLight: '#cce4ff',
      red: '#ff3b30',
      redLight: '#ffd8d6',
      gray: '#d1d1d6',
      grayLight: '#f2f2f9',
    },
    extend: {
      width: {
        sidebar: `${SIDEBAR_WIDTH}px`,
        imageTileDouble: `${HALF_TILE_SIDE}px`,
        imageTileTriple: `${THIRD_TILE_SIDE}px`,
      },
      height: {
        imageTileDouble: `${HALF_TILE_SIDE * 1.3}px`,
        imageTileTriple: `${THIRD_TILE_SIDE * 1.3}px`,
        simpleTile: '64px',
      },
      boxShadow: {
        '3xl': '0px 0px 16px 4px rgba(0, 0, 0, 0.1)',
      },
      flex: {
        twoInRow: '0 0 calc((100% - 4px) / 2)',
        threeInRow: '0 0 calc((100% - 6px) / 3)',
        fourInRow: '0 0 calc((100% - 8px) / 4)',
      },
      animation: {
        fade: 'fade 0.3s linear',
      },
      keyframes: {
        fade: {
          '0%': { opacity: '0' },
          '100%': { opacity: '1' },
        },
      },
      inset: {
        modelCenter: `calc((100% + ${VIEWER_OFFSET}px) / 2)`,
      },
      screens: {
        fullUI: `${SIDEBAR_WIDTH + MODEL_BUFFER_WIDTH + TOOLBAR_WIDTH}px`,
      },
    },
  },
} satisfies Config
