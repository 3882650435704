import { Button, Form } from 'react-aria-components'
import TextField from './TextField'
import { setContactFormValue } from '../../../client/common/actions'
import { useAppDispatch } from '../../../client/configureStore'

type FormDataEntries = {
  [key: string]: string
}

type Props = {
  onSubmit: (data: FormDataEntries) => void
}

const ContactForm = ({ onSubmit }: Props) => {
  const dispatch = useAppDispatch()

  return (
    <Form
      className="flex flex-col items-center gap-4"
      onSubmit={(e) => {
        e.preventDefault()
        let data = Object.fromEntries(
          new FormData(e.currentTarget),
        ) as FormDataEntries
        onSubmit(data)
      }}
    >
      <TextField
        name="name"
        label="Name"
        isRequired
        onChange={(value) => {
          dispatch(setContactFormValue('name', value))
        }}
      />
      <TextField
        name="phone"
        label="Phone"
        onChange={(value) => {
          dispatch(setContactFormValue('phone', value))
        }}
      />
      <TextField
        name="email"
        label="Email"
        type="email"
        isRequired
        onChange={(value) => {
          dispatch(setContactFormValue('email', value))
        }}
      />
      <TextField
        name="pwd"
        label="pwd"
        onChange={(value) => {
          dispatch(setContactFormValue('turingTest', value))
        }}
        className="pointer-events-none absolute -z-50 h-0 w-0 opacity-0"
      />
      <Button
        type="submit"
        className="inline-flex justify-center rounded-lg bg-blue-100 px-4 py-3 font-semibold text-blueLight transition-colors hover:bg-blue-200"
      >
        Submit
      </Button>
    </Form>
  )
}

export default ContactForm
