/* eslint-disable @eslint-react/no-missing-key */

import {
  createExprEval,
  type ExprEvalValues,
  hasLengthAtLeast,
  type Immutable,
  type LengthAtLeast,
  mapSameLengthAtLeast,
  type PartialWithUndefined,
} from '@orangelv/utils';
import {
  angle2,
  type BoundingBox2,
  cm2px,
  degreesToRadians,
  extractSubPath,
  getClosestPoint,
  in2px,
  intersectLineWithPolygon,
  isNegligible,
  lerp2,
  multiply2,
  normalize2,
  pt2px,
  px2in,
  radiansToDegrees,
  rotate2,
  subtract2,
  translateUsingBearing2,
  type Vector2,
} from '@orangelv/utils-geometry';
import type { Notch, SizeWithMetrics } from '@orangelv/utils-olvpf';
import {
  cleanup,
  colorizeSvg,
  isolateIds,
  scale,
  smartStretch,
  type SvgData,
  svgRotate,
  svgScale,
  svgTranslate,
  toSVGPolyline,
} from '@orangelv/utils-svg';
import type { Element as HastElement, Root } from 'hast';
import { selectAll } from 'hast-util-select';
import type { Font } from 'opentype.js';

import { getSubBraidSetups, getVisibleThickness } from './braids.js';
import { renderDebugDot } from './debug.js';
import { getOffsetPath, shootAlongVector } from './points.js';
import { renderText } from './text.js';
import type {
  Anchor,
  Annotation,
  BoundingBox as BoundingBoxNoCoords,
  Braids,
  DesignPart,
  ExprPoint,
  Fill,
  HorizontalAlignment,
  SvgElement,
  TextElement,
  VerticalAlignment,
} from './types.js';

const FILL_RECT_SIZE = cm2px(200);

export function getBoundingBoxNumbers(
  exprEval: ReturnType<typeof createExprEval>,
  boundingBox: BoundingBoxNoCoords,
): { width: number; height: number } {
  const width =
    typeof boundingBox.width === 'string' ?
      exprEval(boundingBox.width)
    : boundingBox.width;

  const height =
    typeof boundingBox.height === 'string' ?
      exprEval(boundingBox.height)
    : boundingBox.height;

  if (typeof width !== 'number') throw new TypeError('Bad width expression');
  if (typeof height !== 'number') throw new TypeError('Bad height expression');

  return { width, height };
}

/**
 * Calculate the position of annotation based on the desired `location`.
 *
 * @remarks
 *
 * Location vs. position: {@link https://gis.stackexchange.com/a/217724/222088}
 */
function getAnnotationPosition(
  cutLine: LengthAtLeast<Immutable<Vector2[]>, 3>,
  cutLineBox: BoundingBox2,
  horizontalAlignment: string,
  verticalAlignment: string,
  marginX: number,
  marginY: number,
): [x: number, y: number, anchor: 'start' | 'middle' | 'end', angle?: number] {
  switch (horizontalAlignment) {
    case 'middle': {
      const intersections = intersectLineWithPolygon(
        cutLine,
        { x: cutLineBox.x + cutLineBox.width / 2, y: 0 },
        { x: cutLineBox.x + cutLineBox.width / 2, y: 1 },
        1,
      ).sort((a, b) => a.y - b.y);
      switch (verticalAlignment) {
        case 'top': {
          const intersection = intersections[0];
          if (!intersection) throw new Error('Missing intersection');
          return [intersection.x, intersection.y + marginY, 'middle'];
        }

        case 'bottom': {
          const intersection = intersections.at(-1);
          if (!intersection) throw new Error('Missing intersection');
          return [intersection.x, intersection.y - 2 * marginY, 'middle'];
        }

        default: {
          throw new Error('Not implemented');
        }
      }
    }

    case 'left': {
      switch (verticalAlignment) {
        case 'top': {
          return [cutLineBox.x + marginX, cutLineBox.y + marginY, 'start'];
        }

        case 'bottom': {
          return [
            cutLineBox.x + marginX,
            cutLineBox.y + cutLineBox.height - marginY,
            'start',
          ];
        }

        case 'side': {
          return [
            cutLineBox.x + marginX,
            cutLineBox.y + cutLineBox.height / 2,
            'middle',
            90,
          ];
        }

        default: {
          throw new Error('Not implemented');
        }
      }
    }

    case 'right': {
      switch (verticalAlignment) {
        case 'top': {
          return [
            cutLineBox.x + cutLineBox.width - marginX,
            cutLineBox.y + marginY,
            'end',
          ];
        }

        case 'bottom': {
          return [
            cutLineBox.x + cutLineBox.width - marginX,
            cutLineBox.y + cutLineBox.height - marginY,
            'end',
          ];
        }

        case 'side': {
          return [
            cutLineBox.x + cutLineBox.width - marginX,
            cutLineBox.y + cutLineBox.height / 2,
            'middle',
            -90,
          ];
        }

        default: {
          throw new Error('Not implemented');
        }
      }
    }

    case 'slanted': {
      let p1;
      let p2;
      switch (verticalAlignment) {
        case 'top': {
          p1 = getClosestPoint(cutLine, {
            x: cutLineBox.x - in2px(2),
            y: cutLineBox.y - in2px(2),
          });
          p2 = getClosestPoint(cutLine, {
            x: cutLineBox.x + cutLineBox.width + in2px(2),
            y: cutLineBox.y - in2px(2),
          });
          break;
        }

        case 'side': {
          // Todo(MaikuMori): Probably not the best place to do this.
          // Detect square collars.
          const test = getClosestPoint(cutLine, {
            x: cutLineBox.x,
            y: cutLineBox.y,
          });
          if (
            isNegligible(test.x - cutLineBox.x, 1) &&
            isNegligible(test.y - cutLineBox.y, 1)
          ) {
            p1 = test;
            p2 = getClosestPoint(cutLine, {
              x: cutLineBox.x + cutLineBox.width,
              y: cutLineBox.y,
            });
          } else {
            p1 = getClosestPoint(cutLine, {
              x: cutLineBox.x + (cutLineBox.width * 2) / 3,
              y: cutLineBox.y - in2px(2),
            });
            p2 = getClosestPoint(cutLine, {
              x: cutLineBox.x + cutLineBox.width + in2px(4),
              y: cutLineBox.y + cutLineBox.height / 2,
            });
          }

          break;
        }

        case 'middle': {
          const fractionsToTry = [
            [3 / 8, 5 / 8],
            [2 / 8, 6 / 8],
            [1 / 8, 7 / 8],
          ] as const;
          let found = false;
          for (const [p1f, p2f] of fractionsToTry) {
            p1 = getClosestPoint(cutLine, {
              x: cutLineBox.x + cutLineBox.width,
              y: cutLineBox.y + cutLineBox.height * p1f,
            });
            p2 = getClosestPoint(cutLine, {
              x: cutLineBox.x + cutLineBox.width,
              y: cutLineBox.y + cutLineBox.height * p2f,
            });
            if (p1.x !== p2.x || p1.y !== p2.y) {
              found = true;
              break;
            }
          }

          if (!found) throw new Error("Can't calculate slanted text position");
          break;
        }

        default: {
          throw new Error('Not implemented');
        }
      }

      if (p1 && p2) {
        const a = -angle2(p1, p2);
        const p12 = subtract2(p2, p1);
        const v =
          p12.x === 0 && p12.y === 0 ?
            p12
          : multiply2(
              marginY / 3,
              normalize2(rotate2(p12, degreesToRadians(90))),
            );
        const mid = lerp2(p1, p2, 0.5);
        return [mid.x + v.x, mid.y + v.y, 'middle', 360 - radiansToDegrees(a)];
      }

      break;
    }

    default: {
      break;
    }
  }

  throw new Error("Can't calculate text position");
}

export function anchorThing(
  anchors: Record<string, Vector2>,
  thing: { height: number; width: number },
  anchor: Anchor,
): Vector2 {
  const [verticalAlignment, horizontalAlignment] = anchor.from.split('-') as [
    VerticalAlignment | undefined,
    HorizontalAlignment | undefined,
  ];
  if (verticalAlignment === undefined || horizontalAlignment === undefined) {
    throw new Error('Bad anchor.from');
  }

  let fromX;
  switch (horizontalAlignment) {
    case 'left': {
      fromX = 0;
      break;
    }

    case 'center': {
      fromX = -thing.width / 2;
      break;
    }

    case 'right': {
      fromX = -thing.width;
      break;
    }
  }

  let fromY;
  switch (verticalAlignment) {
    case 'top': {
      fromY = 0;
      break;
    }

    case 'center': {
      fromY = -thing.height / 2;
      break;
    }

    case 'bottom': {
      fromY = -thing.height;
      break;
    }
  }

  const anchorTo = anchors[anchor.to];
  if (!anchorTo) throw new Error(`Bad anchor.to (${anchor.to})`);

  return { x: fromX + anchorTo.x, y: fromY + anchorTo.y };
}

function renderNotch(notch: Notch, origin: Vector2): string {
  switch (notch.type) {
    case 'slit': {
      return `M${toSVGPolyline([
        origin,
        translateUsingBearing2(origin, notch.angle, notch.depth),
      ])}`;
    }

    case 't': {
      const junction = translateUsingBearing2(origin, notch.angle, notch.depth);
      return `M${toSVGPolyline([origin, junction])}M${toSVGPolyline([
        translateUsingBearing2(
          junction,
          notch.angle - Math.PI / 2,
          notch.width / 2,
        ),
        translateUsingBearing2(
          junction,
          notch.angle + Math.PI / 2,
          notch.width / 2,
        ),
      ])}`;
    }

    default: {
      throw new TypeError('Not implemented');
    }
  }
}

export type LoadSvg = (path: string) => SvgData | Promise<SvgData>;
export type LoadFont = (path: string) => Font | Promise<Font>;

function getOffset(
  offset: Vector2 | ExprPoint,
  exprEval: ReturnType<typeof createExprEval>,
): Vector2 {
  const x = typeof offset.x === 'string' ? exprEval(offset.x) : offset.x;
  if (typeof x !== 'number') throw new TypeError('Bad X offset');
  const y = typeof offset.y === 'string' ? exprEval(offset.y) : offset.y;
  if (typeof y !== 'number') throw new TypeError('Bad Y offset');
  return { x, y };
}

const CUT_LINE_THICKNESS = pt2px(3.6);

export async function renderPiece(
  {
    cutLine,
    gradeReferenceLine,
    grainLine,
    internalLines,
    sewLines,
    notches,
    anchors,
    cutLineBox,
    sewLineBoxes,
    sewLineBox,
    corners,
    anchorToSewLines,
  }: SizeWithMetrics,
  loadSvg: LoadSvg,
  loadFont: LoadFont,
  {
    braids,
    factory: {
      annotation,
      markingOuterColor,
      markingInnerColor,
      notchOuterColor,
      notchInnerColor,
      cutLineOuterColor,
      cutLineInnerColor,
    } = {},
    debug = false,
    debugAnchors = true,
    designColors = {},
    designParts = [],
    exprEvalValues,
    fill,
    imageElements = [],
    markings = [],
    textElements = [],
  }: PartialWithUndefined<{
    braids: Braids;
    factory: PartialWithUndefined<{
      annotation: Annotation;
      markingOuterColor: string;
      markingInnerColor: string;
      notchOuterColor: string;
      notchInnerColor: string;
      cutLineOuterColor: string;
      cutLineInnerColor: string;
    }>;
    debug: boolean;
    debugAnchors: boolean;
    designColors: Record<string, string>;
    designParts: DesignPart[];
    exprEvalValues: ExprEvalValues;
    fill: Fill;
    imageElements: SvgElement[];
    markings: Vector2[];
    textElements: TextElement[];
  }> = {},
): Promise<SvgData> {
  const exprEval = createExprEval({
    in2px,
    pt2px,
    cm2px,
    px2in,
    anchors,
    cutLineBox,
    sewLineBoxes,
    sewLineBox,
    h: sewLineBox?.height,
    ...exprEvalValues,
  });

  const anchorLine =
    anchorToSewLines ? sewLines[0] : { isClosed: true, vertices: cutLine };

  const patterns: Record<string, Root | HastElement> = {};

  const texts = await Promise.all(
    textElements.map(async (text) => {
      const fillValue =
        typeof text.color === 'string' ?
          text.color
        : colorizeSvg(await loadSvg(text.color.name), text.color.colors);

      const textSvg = renderText(
        await loadFont(text.font),
        String(exprEval(text.text)),
        {
          size: text.size,
          fill: fillValue,
          outlineColor1: text.outlineColor1,
          outlineColor2: text.outlineColor2,
          letterSpacing: text.letterSpacing,
          layout: text.layout,
          boxSizing: text.boxSizing ?? 'content-box',
          tail: text.tail && {
            svg: await loadSvg(text.tail.name),
            isConnected: text.tail.isConnected,
            ...(text.tail.text === undefined ?
              undefined
            : {
                text: text.tail.text,
                textFont: await loadFont(text.tail.textFont),
                textColor: text.tail.textColor,
              }),
          },
          isVertical: text.isVertical,
        },
      );

      const boundingBox =
        text.boundingBox ?
          getBoundingBoxNumbers(exprEval, text.boundingBox)
        : textSvg;
      const scaleFactor = text.scaleFactor ?? 1;
      const rotation = text.rotation ?? 0;

      const scalingRatio =
        Math.min(
          boundingBox.width / textSvg.width,
          boundingBox.height / textSvg.height,
        ) * scaleFactor;

      const scalingOffset = {
        x: (boundingBox.width - textSvg.width * scalingRatio) / 2,
        y: (boundingBox.height - textSvg.height * scalingRatio) / 2,
      };

      const offset = anchorThing(anchors, boundingBox, text.anchor);

      const textOffset = getOffset(text.anchor.offset, exprEval);

      return (
        <g
          transform={
            // 3rd align.
            svgTranslate(offset.x, offset.y) +
            // 2nd rotate.
            (rotation === 0 ? '' : (
              svgRotate(rotation, boundingBox.width / 2, boundingBox.height / 2)
            )) +
            // 1st translate offset.
            svgTranslate(textOffset.x, textOffset.y)
          }
        >
          {debug ?
            <rect
              width={boundingBox.width}
              height={boundingBox.height}
              fill="none"
              stroke="#CF0134"
            />
          : undefined}
          <g
            transform={
              svgTranslate(scalingOffset.x, scalingOffset.y) +
              svgScale(scalingRatio, scalingRatio)
            }
          >
            {textSvg.root}
          </g>
        </g>
      );
    }),
  );

  const images = await Promise.all(
    imageElements.map(async (image, imageIndex) => {
      const { name, colors, extraAttributes } = image.svg;

      const imageSvg = colorizeSvg(
        await loadSvg(String(exprEval(name))),
        colors,
      );

      if (extraAttributes) {
        // For each selector, apply given extra attributes to every element that matches.
        for (const [selector, selectorAttributes] of Object.entries(
          extraAttributes,
        )) {
          const elements = selectAll(selector, imageSvg.root);
          for (const [property, value] of Object.entries(selectorAttributes)) {
            for (const element of elements) {
              element.properties[property] = value;
            }
          }
        }
      }

      const boundingBox =
        image.boundingBox ?
          getBoundingBoxNumbers(exprEval, image.boundingBox)
        : imageSvg;
      const scaleFactor = image.scaleFactor ?? 1;
      const rotation = image.rotation ?? 0;

      const scalingRatio =
        Math.min(
          boundingBox.width / imageSvg.width,
          boundingBox.height / imageSvg.height,
        ) * scaleFactor;

      const needsClipping = scaleFactor > 1;

      const scalingOffset = {
        x: (boundingBox.width - imageSvg.width * scalingRatio) / 2,
        y: (boundingBox.height - imageSvg.height * scalingRatio) / 2,
      };

      const offset = anchorThing(anchors, boundingBox, image.anchor);
      const imageOffset = getOffset(image.anchor.offset, exprEval);
      const clipId = `clip-path-${imageIndex}`;

      return (
        <g
          transform={
            // 3rd align.
            svgTranslate(offset.x, offset.y) +
            // 2nd rotate.
            (rotation === 0 ? '' : (
              svgRotate(rotation, boundingBox.width / 2, boundingBox.height / 2)
            )) +
            // 1st translate offset.
            svgTranslate(imageOffset.x, imageOffset.y)
          }
        >
          {debug ?
            <rect
              width={boundingBox.width}
              height={boundingBox.height}
              fill="none"
              stroke="#CF0134"
            />
          : undefined}
          {needsClipping ?
            <clipPath id={clipId}>
              <rect width={boundingBox.width} height={boundingBox.height} />
            </clipPath>
          : undefined}
          <g clipPath={needsClipping ? `url(#${clipId})` : undefined}>
            <g
              transform={
                svgTranslate(scalingOffset.x, scalingOffset.y) +
                svgScale(scalingRatio, scalingRatio)
              }
            >
              {imageSvg.root}
            </g>
          </g>
        </g>
      );
    }),
  );

  let annotationGroup;
  if (annotation) {
    const horizontalAlignment = exprEval(annotation.horizontalAlignment);
    if (typeof horizontalAlignment !== 'string') {
      throw new TypeError('Bad annotation horizontalAlignment expression');
    }

    const verticalAlignment = exprEval(annotation.verticalAlignment);
    if (typeof verticalAlignment !== 'string') {
      throw new TypeError('Bad annotation verticalAlignment expression');
    }

    const marginX = exprEval(annotation.marginX);
    if (typeof marginX !== 'number') {
      throw new TypeError('Bad annotation marginX expression');
    }

    const marginY = exprEval(annotation.marginY);
    if (typeof marginY !== 'number') {
      throw new TypeError('Bad annotation marginY expression');
    }

    const [centerX, centerY, anchor, angle] = getAnnotationPosition(
      cutLine,
      cutLineBox,
      horizontalAlignment,
      verticalAlignment,
      marginX,
      marginY,
    );
    const annotationFont = await loadFont(annotation.font);
    const annotationText = exprEval(annotation.text);
    if (typeof annotationText !== 'string') {
      throw new TypeError('Bad annotation text');
    }

    const svg = renderText(annotationFont, annotationText, {
      size: pt2px(10),
      outlineColor1: annotation.strokeColor,
      fill: annotation.color,
      boxSizing: 'content-box',
    });
    for (const p of selectAll('path', svg.root)) {
      p.properties['strokeWidth'] = pt2px(3);
    }

    let annotationTranslate: Vector2;
    switch (anchor) {
      case 'start': {
        annotationTranslate = { x: 0, y: svg.height / 2 };
        break;
      }

      case 'middle': {
        annotationTranslate = { x: -svg.width / 2, y: svg.height / 2 };
        break;
      }

      case 'end': {
        annotationTranslate = { x: -svg.width, y: svg.height / 2 };
        break;
      }
    }

    annotationGroup = (
      <g
        transform={
          svgTranslate(centerX, centerY) +
          (angle === undefined ? '' : svgRotate(angle)) +
          svgTranslate(annotationTranslate.x, annotationTranslate.y)
        }
      >
        {svg.root}
      </g>
    );
  }

  const designPromises = designParts.map(
    async (
      {
        assetName,
        flipX = false,
        repeatX = false,
        repeatY = false,
        anchorOrigin,
        anchorTarget,
        translateX: translateXExpr,
        translateY: translateYExpr,
        spreadX: spreadXExpr,
        scale: scaleExpr,
        maskParts,
      },
      index,
    ) => {
      const asset = colorizeSvg(await loadSvg(assetName), designColors);
      const patternId = `design-part-${index}`;

      // Some design parts, for example, have different clip paths with the
      // same ID, which results in weird order-dependent behavior.
      isolateIds(asset, patternId);

      if (spreadXExpr !== undefined) {
        const spreadX = exprEval(spreadXExpr);
        if (typeof spreadX !== 'number') throw new TypeError('Bad spreadX');
        smartStretch(asset, spreadX, 0);
      }

      if (scaleExpr !== undefined) {
        const ratio = exprEval(scaleExpr);
        if (typeof ratio !== 'number') throw new TypeError('Bad scale');
        scale(asset, ratio);
      }

      const offset = anchorThing(anchors, asset, {
        from: anchorOrigin ?? 'center-center',
        to: anchorTarget ?? 'document-center',
        offset: { x: 0, y: 0 },
      });

      let mask;
      if (maskParts && hasLengthAtLeast(maskParts, 1)) {
        if (!anchorLine) throw new Error('Missing anchor line');
        if (!anchorLine.isClosed) throw new Error('Anchor line not closed');

        const maskPaths = mapSameLengthAtLeast(maskParts, (x) => {
          const [fromTo, thicknessExpr, ...colonRest] = x
            .split(':')
            .map((y) => y.trim());

          const [fromRaw, toRaw, ...fromToRest] = (fromTo ?? '')
            .split('~')
            .map((y) => y.trim());

          const [from, fromTailExpr, ...fromRest] = (fromRaw ?? '').split('#');
          const [to, toTailExpr, ...toRest] = (toRaw ?? '').split('#');

          if (
            thicknessExpr === undefined ||
            colonRest.length > 0 ||
            fromToRest.length > 0 ||
            from === undefined ||
            fromRest.length > 0 ||
            to === undefined ||
            toRest.length > 0
          ) {
            throw new Error(`Bad mask part: ${x}`);
          }

          const thickness = exprEval(thicknessExpr);
          if (typeof thickness !== 'number') {
            throw new TypeError(`Bad mask thickness: ${thicknessExpr}`);
          }

          const fromCorner = corners[from];
          if (!fromCorner) throw new Error(`Bad mask from: ${from}`);
          const toCorner = corners[to];
          if (!toCorner) throw new Error(`Bad mask to: ${to}`);

          const subPath = extractSubPath(
            anchorLine.vertices,
            fromCorner.index,
            toCorner.index,
          );

          if (hasLengthAtLeast(subPath, 2)) {
            if (fromTailExpr !== undefined) {
              const fromTail =
                fromTailExpr === '' ? FILL_RECT_SIZE : exprEval(fromTailExpr);
              if (typeof fromTail !== 'number' || fromTail < 0) {
                throw new TypeError(`Bad mask from tail: ${fromTailExpr}`);
              }

              subPath.unshift(
                shootAlongVector(subPath[0], subPath[1], subPath[0], fromTail),
              );
            }

            if (toTailExpr !== undefined) {
              const toTail =
                toTailExpr === '' ? FILL_RECT_SIZE : exprEval(toTailExpr);
              if (typeof toTail !== 'number' || toTail < 0) {
                throw new TypeError(`Bad mask to tail: ${toTailExpr}`);
              }

              const a = subPath.at(-2);
              const b = subPath.at(-1);
              if (!a || !b) throw new Error('Logic error');

              subPath.push(shootAlongVector(b, a, b, toTail));
            }
          }

          return {
            thickness,
            path: (
              <path
                d={`M ${toSVGPolyline(subPath)}`}
                stroke={thickness < 0 ? 'black' : 'white'}
                strokeWidth={Math.abs(thickness) * 2}
                strokeLinejoin="round"
                strokeLinecap="square"
              />
            ),
          };
        });

        const id = `design-part-${index}-mask`;
        mask = {
          reference: `url(#${id})`,
          element: (
            <mask id={id}>
              <rect
                x={cutLineBox.x - 1}
                y={cutLineBox.y - 1}
                width={cutLineBox.width + 2}
                height={cutLineBox.height + 2}
                fill={maskPaths[0].thickness < 0 ? 'white' : 'black'}
              />
              {maskPaths.map((x) => x.path)}
            </mask>
          ),
        };
      }

      const translateX =
        repeatX || translateXExpr === undefined ? 0 : exprEval(translateXExpr);
      if (typeof translateX !== 'number') throw new TypeError('Bad translateX');

      const translateY =
        repeatY || translateYExpr === undefined ? 0 : exprEval(translateYExpr);
      if (typeof translateY !== 'number') throw new TypeError('Bad translateY');

      return (
        <>
          {mask?.element}
          <g mask={mask?.reference}>
            <g
              transform={
                // 3rd align.
                svgTranslate(
                  repeatX ?
                    cutLineBox.x + cutLineBox.width / 2 - FILL_RECT_SIZE / 2
                  : offset.x,
                  repeatY ?
                    cutLineBox.y + cutLineBox.height / 2 - FILL_RECT_SIZE / 2
                  : offset.y,
                ) +
                // 2nd rotate (if needed in the future).
                // 1st translate offset.
                svgTranslate(translateX, translateY)
              }
            >
              <pattern
                id={patternId}
                patternUnits="userSpaceOnUse"
                patternTransform={flipX ? 'scale(-1, 1)' : undefined}
                width={asset.width}
                height={asset.height}
              >
                {asset.root}
              </pattern>
              <rect
                width={repeatX ? FILL_RECT_SIZE : asset.width}
                height={repeatY ? FILL_RECT_SIZE : asset.height}
                fill={`url(#${patternId})`}
              />
            </g>
          </g>
        </>
      );
    },
  );

  // Fill.
  let fillValue = 'transparent';
  if (typeof fill === 'string') {
    fillValue = fill;
  } else if (fill !== undefined) {
    const p = colorizeSvg(await loadSvg(fill.name), fill.colors);
    fillValue = `url(#${p.name})`;

    if (!patterns[p.name]) {
      patterns[p.name] = (
        <pattern
          id={p.name}
          patternUnits="userSpaceOnUse"
          width={p.width}
          height={p.height}
        >
          {p.root}
        </pattern>
      );
    }
  }

  // Braids.
  const braidPaths = [];
  if (braids) {
    for (const {
      // position,
      reverseColors,
      from,
      to,
      offset,
      radius,
      curveEdges,
    } of braids.parts) {
      const setups = getSubBraidSetups(
        reverseColors ?
          ([...braids.colors].reverse() as typeof braids.colors)
        : braids.colors,
        braids.thickness,
      );

      if (!anchorLine) throw new Error('Missing anchor line');
      if (!anchorLine.isClosed) throw new Error('Anchor line not closed');
      const fromIndex = corners[from]?.index;
      if (fromIndex === undefined) throw new Error('Bad from');
      const toIndex = corners[to]?.index;
      if (toIndex === undefined) throw new Error('Bad to');
      const subPath = extractSubPath(anchorLine.vertices, fromIndex, toIndex);

      const evalValues = { t: getVisibleThickness(setups) };
      const offsetEvaluated = exprEval(offset, evalValues);
      if (typeof offsetEvaluated !== 'number') {
        throw new TypeError('Bad offset');
      }

      const radiusEvaluated =
        radius === undefined ? 0 : exprEval(radius, evalValues);
      if (typeof radiusEvaluated !== 'number') {
        throw new TypeError('Bad radius');
      }

      for (const setup of setups) {
        braidPaths.push(
          <path
            d={getOffsetPath(
              subPath,
              offsetEvaluated + setup.offsetDelta,
              radiusEvaluated + setup.radiusDelta,
              !!curveEdges,
              1,
            )}
            strokeWidth={setup.thickness}
            stroke={setup.color}
            fill="none"
          />,
        );
      }
    }
  }

  const cutLinePath = `M${toSVGPolyline(cutLine)}z`;
  const cutLineNotches = notches
    .filter((x) => x.line === 'cut')
    .map((x) => {
      const point = cutLine[x.pointIndex];
      if (!point) throw new TypeError('Bad pointIndex');
      return renderNotch(x, point);
    })
    .join('');

  const debugLine =
    debug &&
    [
      gradeReferenceLine && `M${toSVGPolyline(gradeReferenceLine)}`,
      grainLine && `M${toSVGPolyline(grainLine)}`,
      ...[...internalLines, ...sewLines].map(
        (x) => `M${toSVGPolyline(x.vertices)}${x.isClosed ? 'z' : ''}`,
      ),
      ...notches
        .filter((x): x is Extract<Notch, { line: 'sew' }> => x.line === 'sew')
        .map((x) => {
          const point = sewLines[x.lineIndex]?.vertices[x.pointIndex];
          if (!point) throw new TypeError('Bad lineIndex or pointIndex');
          return renderNotch(x, point);
        }),
    ].join('');

  // 4 is the default miter limit, the furthest it can deviate
  const margin = cutLineOuterColor === undefined ? 0 : CUT_LINE_THICKNESS * 4;

  const svg = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      // Set with and height in inches and
      // viewBox so that 1 inch == `PPI` as defined in `points.ts`.
      width={`${px2in(cutLineBox.width) + px2in(margin) * 2}in`}
      height={`${px2in(cutLineBox.height) + px2in(margin) * 2}in`}
      viewBox={`${cutLineBox.x - margin} ${cutLineBox.y - margin} ${
        cutLineBox.width + margin * 2
      } ${cutLineBox.height + margin * 2}`}
    >
      <defs>
        {Object.values(patterns)}
        <clipPath id="cp-cutLine">
          <path d={cutLinePath} />
        </clipPath>
      </defs>
      {/* Outer cut line is here so that it's "clipped" by the fill, preventing
      anti-aliasing artifacts with the inner counterpart. */}
      {cutLineOuterColor === undefined ?
        undefined
      : <path
          id="CUT-OUTER"
          d={cutLinePath}
          fill="none"
          stroke={cutLineOuterColor}
          strokeWidth={CUT_LINE_THICKNESS * 2}
          strokeLinejoin="miter"
        />
      }
      <g id="CLIPPED" clipPath="url(#cp-cutLine)">
        <rect
          id="FILL"
          x={cutLineBox.x + cutLineBox.width / 2 - FILL_RECT_SIZE / 2}
          y={cutLineBox.y + cutLineBox.height / 2 - FILL_RECT_SIZE / 2}
          height={FILL_RECT_SIZE}
          width={FILL_RECT_SIZE}
          fill={fillValue}
        />
        <g id="ARTWORK">
          {await Promise.all(designPromises)}
          {braidPaths.length === 0 ?
            undefined
          : <g id="BRAIDS">{braidPaths}</g>}
          {images}
          {texts}
        </g>
        {annotationGroup ?
          <g id="ANNOTATION">{annotationGroup}</g>
        : undefined}
        {(
          markings.length === 0 ||
          (markingOuterColor === undefined && markingInnerColor === undefined)
        ) ?
          undefined
        : <g id="MARKINGS">
            {...markings.map(({ x, y }) => (
              <>
                {markingOuterColor === undefined ?
                  undefined
                : <circle
                    cx={x}
                    cy={y}
                    r={in2px(1 / 16)}
                    fill={markingOuterColor}
                  />
                }
                {markingInnerColor === undefined ?
                  undefined
                : <circle
                    cx={x}
                    cy={y}
                    r={in2px(1 / 32)}
                    fill={markingInnerColor}
                  />
                }
              </>
            ))}
          </g>
        }
        {cutLineNotches === '' || notchOuterColor === undefined ?
          undefined
        : <path
            id="NOTCH-OUTER"
            d={cutLineNotches}
            fill="none"
            stroke={notchOuterColor}
            strokeWidth="6pt"
            strokeLinecap="round"
          />
        }
        {cutLineNotches === '' || notchInnerColor === undefined ?
          undefined
        : <path
            id="NOTCH-INNER"
            d={cutLineNotches}
            fill="none"
            stroke={notchInnerColor}
            strokeWidth="2pt"
            strokeLinecap="round"
          />
        }
        {cutLineInnerColor === undefined ?
          undefined
        : <path
            id="CUT-INNER"
            d={cutLinePath}
            fill="none"
            stroke={cutLineInnerColor}
            strokeWidth={CUT_LINE_THICKNESS * 2}
            strokeLinejoin="miter"
          />
        }
      </g>
      {debug ?
        <g id="DEBUG">
          <path
            id="debugLine"
            d={debugLine}
            fill="none"
            stroke="#33E0FF"
            strokeWidth="2pt"
          />
          {debugAnchors ?
            <g id="ANCHORS">
              {Object.entries(anchors).map(([key, point]) =>
                renderDebugDot('ANCHORS', key, point),
              )}
            </g>
          : undefined}
        </g>
      : undefined}
    </svg>
  );

  return cleanup({
    name: 'NO_NAME',
    root: svg,
    height: cutLineBox.height + margin * 2,
    width: cutLineBox.width + margin * 2,
  });
}
